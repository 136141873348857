<template>
  <div>
    <div>{{ ht }}</div>
    <input v-model="a" />
    <input v-model="b" />
    <button @click="aa">aa</button>
    <!-- 隐藏的 quill 编辑器 -->
    <div id="tipquill" style="display: none"></div>

    <button @click="set_tent">使用当前租户</button>

    <!-- 按钮区域 -->
    <div style="margin: 20px 0">
      <button @click="checkDataCount">显示数据条数</button>
      <span v-if="totalDataCount !== null">
        共有数据：{{ totalDataCount }} 条
      </span>
    </div>
    <div style="margin: 20px 0">
      <!-- 输入起始页 -->
      <label>
        起始页:
        <input
          type="number"
          v-model.number="startPage"
          min="1"
          :max="totalPages"
        />
      </label>
    </div>
    <div style="margin: 20px 0">
      <button @click="startProcessing" :disabled="processing">
        {{ processing ? "处理中..." : "开始" }}
      </button>
    </div>
    <div v-if="totalDataCount !== null">
      当前页：{{ currentPage }} / {{ totalPages }} 页
    </div>
  </div>
</template>

<script>
import "@/plugins/quill.custom.js";
import { getAll, putAll, getContent } from "@/network/knowledge";
import { customHeader } from "@/plugins/quill.custom.js";
import QuillBetterTable from "@/plugins/quill-better-table.js";
import Quill from "quill";
import "quill/dist/quill.snow.css";

export default {
  data() {
    return {
      b: "",
      a: "",
      ht: "",
      tent: "",
      quill: null,
      totalDataCount: null, // 总数据条数
      allData: [], // 保存第一页数据（后续批次单独处理）
      processing: false, // 正在处理的标识
      currentPage: 1, // 当前请求页码（显示用）
      pageSize: 10, // 每页数据条数
      totalPages: 0, // 总页数
      startPage: 1, // 用户输入的起始页（默认从第一页开始）
    };
  },
  created() {
    customHeader();
  },
  mounted() {
    Quill.register(
      {
        "modules/better-table": QuillBetterTable,
      },
      true
    );
    this.quill = new Quill("#tipquill", {
      modules: {
        toolbar: false,
        history: {
          delay: 2000,
          maxStack: 500,
        },
        table: false, // 禁用 table 模块
        "better-table": {
          operationMenu: {
            items: {
              insertColumnRight: { text: this.$t("knowledge.InsertRight") },
              insertColumnLeft: { text: this.$t("knowledge.InsertLeft") },
              insertRowUp: { text: this.$t("knowledge.InsertAbove") },
              insertRowDown: { text: this.$t("knowledge.InsertBelow") },
              mergeCells: { text: this.$t("knowledge.MergeCells") },
              unmergeCells: { text: this.$t("knowledge.SplitCells") },
              deleteColumn: { text: this.$t("knowledge.DeleteColumn") },
              deleteRow: { text: this.$t("knowledge.DeleteRow") },
              deleteTable: { text: this.$t("knowledge.DeleteTable") },
            },
          },
        },
        keyboard: {
          bindings: QuillBetterTable.keyboardBindings,
        },
      },
      theme: "snow",
    });
  },
  methods: {
    set_tent() {
      this.tent = this.$store.state.project.tenantId;
    },
    // 点击“显示数据条数”按钮时调用
    checkDataCount() {
      const param = {
        pageNumber: 1,
        tenantId: this.tent,
      };
      getAll(param).then((res) => {
        this.totalDataCount = res.total;
        this.totalPages = Math.ceil(this.totalDataCount / this.pageSize);
        // 保存第一页数据
        res.contents.forEach((item) => {
          if (item.content && item.content.length > 0) {
            this.allData.push({
              id: item.id,
              contentId: item.contentId,
              content: item.content,
              projectId: item.projectId,
              documentId: item.documentId,
            });
          }
        });
        // 更新显示页码（第一页已加载）
        this.currentPage = 1;
        // 默认起始页为第一页
        this.startPage = 1;
      });
    },

    // 开始批次处理数据，每10次请求一批
    async startProcessing() {
      if (this.processing) return;
      if (this.totalDataCount === null) {
        alert("请先点击【显示数据条数】获取数据总数！");
        return;
      }
      this.processing = true;
      // 使用用户输入的起始页，如果输入无效则从第二页开始（因为第一页已经在 checkDataCount 中加载）
      let page = this.startPage > 1 ? this.startPage : 2;

      while (page <= this.totalPages) {
        // 本批次结束页码
        const batchEnd = Math.min(page + 10 - 1, this.totalPages);
        let batchData = [];

        // 请求本批次的每一页
        for (let p = page; p <= batchEnd; p++) {
          try {
            const param = {
              pageNumber: p,
              tenantId: this.$store.state.project.tenantId,
            };
            const res = await getAll(param);
            res.contents.forEach((item) => {
              if (
                item.content &&
                item.content.length > 0 &&
                item.contentHtml.length === 0
              ) {
                batchData.push({
                  id: item.id,
                  contentId: item.contentId,
                  content: item.content,
                  projectId: item.projectId,
                  documentId: item.documentId,
                });
              }
            });
            // 更新当前页显示
            this.currentPage = p;
          } catch (e) {
            console.error(`请求第 ${p} 页失败，跳过该页`, e);
            // 此处可根据业务需求决定是否重试该页请求
          }
          // 可选：每次请求后等待一小段时间
          await this.sleep(100);
        }

        // 批次数据处理和 PUT，直到成功为止
        let batchSuccess = false;
        while (!batchSuccess) {
          try {
            await this.tohtml(batchData);
            batchSuccess = true;
          } catch (err) {
            console.error("当前批次 putAll 失败，重新请求该批次数据", err);
            // 重新请求该批次数据
            batchData = [];
            for (let p = page; p <= batchEnd; p++) {
              try {
                const param = {
                  pageNumber: p,
                  tenantId: this.$store.state.project.tenantId,
                };
                const res = await getAll(param);
                res.contents.forEach((item) => {
                  if (item.content && item.content.length > 0) {
                    batchData.push({
                      id: item.id,
                      contentId: item.contentId,
                      content: item.content,
                      projectId: item.projectId,
                      documentId: item.documentId,
                    });
                  }
                });
                this.currentPage = p;
              } catch (e) {
                console.error(`重试请求第 ${p} 页失败`, e);
              }
              await this.sleep(100);
            }
          }
        }
        // 批次处理完成后，page 指向下一批次的第一页
        page = batchEnd + 1;
      }
      this.processing = false;
      alert("所有数据处理完成！");
    },

    // 数据处理逻辑，返回 Promise
    async tohtml(data) {
      return new Promise((resolve, reject) => {
        const my_p = [
          "52c21b08-6b32-4b82-9c5d-67a23e6e50b3",
          "9953b3ac-6057-4b17-9e33-796b21c74d41",
          "beff368d-74f6-43ee-94b7-436f10bdc1a2",
          "3b4932f4-7999-4654-879a-c1ed5e69a73f",
          "eb5711aa-d168-4a22-90e0-cd9eefd11fa6",
          "3cfd68c7-600e-40ef-bb21-b57be7886613",
          "821d828a-8002-4b47-89f2-143423b26eb6",
          "3b7aa7fe-742f-49d4-aaab-ce7f44fbd963",
          "304be6c2-a364-4146-b0f0-3cc2995e96cf",
          "a1d21c8a-6e00-4551-b6df-fef21af8964a",
          "e30969de-889a-4182-baae-a42e0e9807b6",
          "afde1936-c687-49ff-a2fe-9f761e7f4341",
          "8783444e-a9de-43fe-9aa0-a719de741cc8",
          "06e56401-d2f8-4c83-984e-530ad795b48c",
          "463c9499-40f4-4666-839e-b2db4ee65b34",
          "5d73cc12-32fa-4eb7-bc26-861f160446df",
          "c01d7016-5500-489e-a102-4deea481e24e",
          "f86a4652-bacc-4e7f-88c4-15fc5b4ea726",
          "fedb5b95-1cbe-4d33-ac75-572b17198d49",
          "386a173d-7de3-4157-8d73-47b48d7cf782",
          "e5757445-b19c-4a6a-ad21-c7b678119542",
          "01cec25f-9df8-4c0f-b887-9d1b2629b0d9",
          "ec936646-c4ce-495e-b429-90e883779b2b",
          "c6f439d8-f5b6-4c69-887d-32b5eff770d4",
          "9f8a3544-44d6-4c96-a9c3-4511d8e8d2fd",
          "ef9ae2ae-f3ad-4a56-81a2-d3b1db669e86",
          "99853a82-a11a-48a9-ace4-c88a42f45982",
          "621b8b27-1899-459a-90eb-5887e8213f76",
          "93d235cc-5af7-4cd6-a86e-9e064ad6fea6",
          "53b06625-370d-499e-9da7-90e147c38a3c",
        ];
        console.log("处理数据", data);
        const resArr = [];
        data.forEach((item) => {
          try {
            const delta = JSON.parse(item.content);
            this.quill.setContents(delta);
            let html = this.quill.root.innerHTML;
            resArr.push({
              id: item.id,
              contentId: item.contentId,
              contentHtml: this.modifyHTML(html),
            });
            if (my_p.includes(item.projectId) && item.content.length > 500) {
              console.log(
                `http://localhost/${item.projectId}/knowledge?documentId=${item.documentId}`
              );
            }
          } catch (error) {
            console.error("Error processing item:", item, error);
          }
        });
        console.log("处理后的数据", resArr);
        putAll(resArr)
          .then((r) => {
            console.log("putAll 返回", r);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    change(content) {
      try {
        const delta = JSON.parse(content);
        this.quill.setContents(delta);
        let html = this.quill.root.innerHTML;
        return this.modifyHTML(html);
      } catch (error) {
        console.error("Error in change() with content:", content, error);
        // 根据需要可以返回原始 content 或抛出错误
        throw error;
      }
    },

    aa() {
      const params = {
        projectId: this.a,
        documentId: this.b,
      };
      getContent(params).then((res) => {
        const delta = JSON.parse(res.content);
        this.quill.setContents(delta);
        let html = this.quill.root.innerHTML;
        console.log(html);
        // 示例赋值
        this.ht = this.modifyHTML(html);
        console.log("zzzz", this.ht);
      });
    },

    modifyHTML(html) {
      const div = document.createElement("div");
      div.innerHTML = html;
      // const elements = div.querySelectorAll("span, u, s, em, strong");
      // elements.forEach((element) => {
      //   const style = element.getAttribute("style");
      //   if (style) {
      //     const hasBgColor = style.includes("background-color");
      //     let hasColor = false;
      //     let fontColor = null;
      //     const styleProps = style.split(";");
      //     for (let prop of styleProps) {
      //       if (prop.trim().startsWith("color:")) {
      //         hasColor = true;
      //         fontColor = prop.split(":")[1].trim();
      //         break;
      //       }
      //     }
      //     if (hasBgColor) {
      //       const bgColorMatch = style.match(/background-color:\s*([^;]+)/);
      //       const bgColor = bgColorMatch ? bgColorMatch[1] : null;
      //       const mark = document.createElement("mark");
      //       mark.textContent = element.textContent;
      //       mark.setAttribute("data-color", bgColor);
      //       if (hasColor) {
      //         mark.style.color = "inherit";
      //         mark.style.backgroundColor = bgColor;
      //         const outerSpan = document.createElement("span");
      //         outerSpan.style.color = fontColor;
      //         outerSpan.appendChild(mark);
      //         element.parentNode.replaceChild(outerSpan, element);
      //       } else {
      //         element.parentNode.replaceChild(mark, element);
      //       }
      //     }
      //   }
      // });
      const preTags = div.querySelectorAll("pre[data-language]");
      preTags.forEach((pre) => {
        const language = pre.getAttribute("data-language");
        const code = document.createElement("code");
        code.className = `language-${language}`;
        code.textContent = pre.textContent;
        pre.textContent = "";
        pre.appendChild(code);
        pre.removeAttribute("data-language");
      });
      const pWithImg = div.querySelectorAll("p:has(img)");
      pWithImg.forEach((p) => {
        const pId = p.getAttribute("id");
        const imgs = Array.from(p.querySelectorAll("img")); // 避免动态修改影响 NodeList

        imgs.forEach((img) => {
          if (pId) {
            img.setAttribute("id", pId);
          }

          const isDrawioTrue =
            img.getAttribute("drawio") === "true" ||
            img.getAttribute("drawio") == "true" ||
            img.getAttribute("drawio") == true;

          if (isDrawioTrue) {
            const drawioComponent = document.createElement("drawio-component");

            // 复制所有 img 的属性到 drawioComponent
            for (let i = 0; i < img.attributes.length; i++) {
              const attr = img.attributes[i];
              drawioComponent.setAttribute(attr.name, attr.value || "");
            }

            drawioComponent.setAttribute("url", img.getAttribute("src") || "");

            // 将 img 和 drawio-component 一起放入 p 的父级
            p.parentNode.insertBefore(img, p);
            img.insertAdjacentElement("afterend", drawioComponent);
          } else {
            // 非 drawio 图片直接放回 p 的父级
            p.parentNode.insertBefore(img, p);
          }
        });

        // 删除原来的 p 标签
        p.parentNode.removeChild(p);
      });

      const myAtSpans = div.querySelectorAll("span.my-at");
      myAtSpans.forEach((span) => {
        span.removeAttribute("style");
        span.className = "mention";
        span.setAttribute("data-type", "mention");
        const label = span.textContent.replace("@", "");
        span.setAttribute("data-label", label);
        const id = span.getAttribute("id");
        if (id) {
          span.setAttribute("data-id", id);
        }
      });
      const codeBlockContainers = div.querySelectorAll(
        ".ql-code-block-container"
      );
      codeBlockContainers.forEach((container) => {
        const firstCodeBlock = container.querySelector(".ql-code-block");
        const language = firstCodeBlock
          ? firstCodeBlock.getAttribute("data-language")
          : null;
        if (language) {
          const pre = document.createElement("pre");
          const code = document.createElement("code");
          code.className = `language-${language}`;
          let codeContent = "";
          const codeBlocks = container.querySelectorAll(".ql-code-block");
          codeBlocks.forEach((block) => {
            codeContent += block.textContent + "\n";
          });
          code.textContent = codeContent;
          pre.appendChild(code);
          container.parentNode.replaceChild(pre, container);
        }
      });
      const iframeVideos = div.querySelectorAll("iframe.ql-video");
      iframeVideos.forEach((iframe) => {
        const src = iframe.getAttribute("src");
        if (src) {
          const a = document.createElement("a");
          a.setAttribute("target", "_blank");
          a.setAttribute("rel", "noopener noreferrer nofollow");
          a.setAttribute("href", src);
          a.textContent = src;
          iframe.parentNode.replaceChild(a, iframe);
        }
      });
      const myAlinkSpans = div.querySelectorAll(".my-alink");
      myAlinkSpans.forEach((span) => {
        const link = span.getAttribute("link");
        if (link) {
          const a = document.createElement("a");
          a.setAttribute("target", "_blank");
          a.setAttribute("rel", "noopener noreferrer nofollow");
          a.setAttribute("href", link);
          a.textContent = link;
          span.parentNode.replaceChild(a, span);
        }
      });
      const myAlinkSpans1 = div.querySelectorAll(".my-acard_link");
      myAlinkSpans1.forEach((span) => {
        const link = span.getAttribute("link");
        if (link) {
          const a = document.createElement("a");
          a.setAttribute("target", "_blank");
          a.setAttribute("rel", "noopener noreferrer nofollow");
          a.setAttribute("href", link);
          a.textContent = link;
          span.parentNode.replaceChild(a, span);
        }
      });
      const myAlinkSpans2 = div.querySelectorAll(".my-aview");
      myAlinkSpans2.forEach((span) => {
        const link = span.getAttribute("link");
        if (link) {
          const a = document.createElement("a");
          a.setAttribute("target", "_blank");
          a.setAttribute("rel", "noopener noreferrer nofollow");
          a.setAttribute("href", link);
          a.textContent = link;
          span.parentNode.replaceChild(a, span);
        }
      });
      const myAdescElements = div.querySelectorAll(".my-adesc");
      myAdescElements.forEach((element) => {
        element.parentNode.removeChild(element);
      });
      const myAdescElements1 = div.querySelectorAll(".my-atitle");
      myAdescElements1.forEach((element) => {
        element.parentNode.removeChild(element);
      });
      const olTags = div.querySelectorAll("ol");
      olTags.forEach((ol) => {
        const liWithBullet = ol.querySelectorAll('li[data-list="bullet"]');
        if (liWithBullet.length > 0) {
          const ul = document.createElement("ul");
          for (let i = 0; i < ol.attributes.length; i++) {
            const attr = ol.attributes[i];
            ul.setAttribute(attr.name, attr.value || "");
          }
          while (ol.firstChild) {
            ul.appendChild(ol.firstChild);
          }
          ol.parentNode.replaceChild(ul, ol);
        }
      });
      return div.innerHTML;
    },

    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
  },
  beforeDestroy() {
    // 如果使用定时器，需清除（当前示例均为 async/await，不用额外清理）
  },
};
</script>

<style>
#tipquill {
  height: 70vh;
  overflow: auto;
}
</style>
